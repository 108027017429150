<template>
    <div class="documents flex-column relative" v-if="hasMainLayoutInitialized">
       <DocumentsHeader @set-view="setView" :data="popOverHeaderData" :viewType="viewType"/>
       <div class="documents__content">
          <el-scrollbar v-if="!initializing">
              <div class="documents__scrollbar-content flex-column">
                <StoragePlanBox />
                <DocumentsLayoutSlot
                  :titlePath="titlePath"
                  :viewType="viewType"
                  @on-view-folder="onViewFolder($event, true)"
                  v-if="documentList.length !== 0 || titlePath.length !== 0">
                    <DocumentListTypeComponent
                      v-if="displayType.LIST"
                      :data="popOverData"
                      :list="documentList"
                      @on-view-folder="onViewFolder"
                      @download-file="downloadDocument"
                    />
                    <!-- <DocumentTileTypeFileItemComponent v-for="(item, index) in fileCount" :key="index" :data="popOverData"/> -->
                </DocumentsLayoutSlot>

                <!-- <DocumentsLayoutSlot
                  :titlePath="fileTitle"
                  :viewType="viewType"
                  v-if="fileList.length !== 0">
                    <DocumentListTypeComponent
                      v-if="displayType.LIST"
                      type="file"
                      :data="popOverData"
                      :list="fileList"
                    />
                </DocumentsLayoutSlot> -->

                <DocumentEmptyStateComponent
                  v-if="fileList.length === 0 && folderList.length === 0 && viewType === displayType.LIST"/>
                <!-- TEMPORARY -->
                  <div class="documents__coming-soon flex-column jc-c ai-c" v-show="viewType === displayType.TILE">
                    <div class="documents__img-wrapper flex-column jc-c ai-c">
                      <img src="@/assets/images/construction-gif.gif"/>
                      <p class="documents__construction-text default-text">This section is under<br> construction</p>
                    </div>
                  </div>
                <!-- TEMPORARY -->
              </div>
          </el-scrollbar>
          <Loader v-show="initializing"/>
       </div>

       <!-- MODAL -->
       <CreateNewFolderModal :show="openNewFolderModal" @close="setOpenNewFolderModal(false)"/>
       <FileUploadModal :show="openNewFileUploadModal" @close="setOpenNewFileUploadModal(false)"/>
       <RenameModal :show="openRenameModal" @close="setOpenRenameModal(false)"/>
       <DeleteConfirmationModal :show="openDeleteModal" @close="setOpenDeleteModal(false)"/>
       <MediaDetailsModal :show="openDetailsModal" @close="setOpenDetailsModal(false)"/>
       <DocumentsShareModal :show="openShareModal" @close="setOpenShareModal(false)"/>
       <MoveModal :show="openMoveModal" @close="setOpenMoveModal(false)"/>
       <DocumentBillingModal :show="openDocumentBillingModal" @close="setOpenDocumentBillingModal(false)"/>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import DocumentEmptyStateComponent from '@/core/components/documents/DocumentEmptyStateComponent.vue';
import DocumentListTypeComponent from '@/core/components/documents/DocumentListTypeComponent.vue';
import DocumentsHeader from '@/core/components/documents/DocumentsHeader.vue';
import DocumentsLayoutSlot from '@/core/components/documents/DocumentsLayoutSlot.vue';
// import DocumentTileTypeFileItemComponent from '@/core/components/documents/DocumentTileTypeFileItemComponent.vue';
// import DocumentTileTypeFolderItemComponent from '@/core/components/documents/DocumentTileTypeFolderItemComponent.vue';
import CreateNewFolderModal from '@/core/components/documents/modal/CreateNewFolderModal.vue';
import DeleteConfirmationModal from '@/core/components/documents/modal/DeleteConfirmationModal.vue';
import DocumentBillingModal from '@/core/components/documents/modal/DocumentBillingModal.vue';
import DocumentsShareModal from '@/core/components/documents/modal/DocumentsShareModal.vue';
import FileUploadModal from '@/core/components/documents/modal/FileUploadModal.vue';
import MediaDetailsModal from '@/core/components/documents/modal/MediaDetailsModal.vue';
import MoveModal from '@/core/components/documents/modal/MoveModal.vue';
import RenameModal from '@/core/components/documents/modal/RenameModal.vue';
import StoragePlanBox from '@/core/components/documents/StoragePlanBox.vue';
import Loader from '@/core/components/ui/Loader.vue';
import displayType from '@/core/constants/display';
import { HEADER_DOCUMENTS_LIST } from '@/core/constants/header';
import $axios from '@/core/utils/axios-api-config';
import { ATTACHMENT_CACHE_STORE } from '@/store/modules/attachment-cache';
import { USER_DOCUMENTS_CACHE } from '@/store/modules/attachment-cache/constants';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import { BILLING_STORE } from '@/store/modules/billing';
import { DOCUMENTS_STORE } from '@/store/modules/documents';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    DocumentsHeader,
    StoragePlanBox,
    DocumentsLayoutSlot,
    // DocumentTileTypeFolderItemComponent,
    // DocumentTileTypeFileItemComponent,
    DocumentListTypeComponent,
    CreateNewFolderModal,
    FileUploadModal,
    RenameModal,
    DeleteConfirmationModal,
    MediaDetailsModal,
    DocumentsShareModal,
    MoveModal,
    DocumentEmptyStateComponent,
    DocumentBillingModal,
    Loader
  },
  data() {
    return {
      HEADER_DOCUMENTS_LIST,
      initializing: false,
      folderCount: 6,
      fileCount: 16,
      viewType: displayType.LIST,
      /* eslint-disable global-require */
      popOverData: [
        // {
        //   icon: `${require('@/assets/icons/filled-share-icon.svg')}`,
        //   name: 'Share',
        //   event: 'openModalShare',
        // },
        {
          icon: `${require('@/assets/icons/filled-edit-icon.svg')}`,
          name: 'Rename',
          event: 'openModalRename',
        },
        {
          icon: `${require('@/assets/icons/filled-move-icon.svg')}`,
          name: 'Move to',
          event: 'openModalMove',
        },
        {
          icon: `${require('@/assets/icons/filled-details-icon.svg')}`,
          name: 'Details',
          event: 'openModalDetails',
        },
        {
          icon: `${require('@/assets/icons/filled-download-icon.svg')}`,
          name: 'Download',
          event: 'downloadDocument',
        },
        {
          icon: `${require('@/assets/icons/delete-icon.svg')}`,
          name: 'Delete',
          event: 'openModalDelete',
        },
      ],
      popOverHeaderData: [
        {
          icon: `${require('@/assets/icons/upload-icon.svg')}`,
          name: 'File upload',
          event: 'openFileUploadModal',
        },
        // {
        //   icon: `${require('@/assets/icons/filled-gray-folder-icon.svg')}`,
        //   name: 'Folder upload',
        //   event: 'folderUpload',
        // },
        {
          icon: `${require('@/assets/icons/create-new-folder-icon.svg')}`,
          name: 'New folder',
          event: 'openModalNewFolder',
        },
      ],
      /* eslint-disable global-require */

      defaultTitlePath: {
        name: HEADER_DOCUMENTS_LIST,
      },
      titlePath: [],
      fileTitle: [{ name: 'Files' }],
      currentFolderList: [],
      currentFileList: [],
      accumlatedSize: '',
      displayType
    };
  },

  computed: {
    ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),
    ...mapGetters(DOCUMENTS_STORE, [
      'openShareModal',
      'openRenameModal',
      'openMoveModal',
      'openDetailsModal',
      'openDeleteModal',
      'openNewFolderModal',
      'openNewFileUploadModal',
      'openDocumentBillingModal',

      'folderList',
      'fileList',
      'openedFolder',
      'documentList'
    ]),

    ...mapGetters(USERS_STORE, ['hasMainLayoutInitialized']),

    ...mapGetters(USER_WORKSTATION, ['activeUserWorkstation']),
  },

  async created() {
    this.setFolderList([]);
    this.setFileList([]);

    this.setIsPropertyDocument(false);

    await this.getList();
    this.resetTitlePath();

    await this.initializeUserState();

    // check successful transaction
    this.checkSuccessfulTransaction();
  },

  methods: {
    ...mapActions(['setPaymentSuccessfulModal']),
    ...mapActions(ATTACHMENT_CACHE_STORE, ['getAttachment']),
    ...mapActions(DOCUMENTS_STORE, [
      'setOpenShareModal',
      'setOpenRenameModal',
      'setOpenMoveModal',
      'setOpenDetailsModal',
      'setOpenDeleteModal',
      'setOpenNewFolderModal',
      'setOpenNewFileUploadModal',
      'setOpenDocumentBillingModal',

      'getUserDocuments',
      'setFolderList',
      'setFileList',
      'setOpenedFolder',
      'getUserDocumentCalculatedSize',
      'setIsPropertyDocument',

      'setDocumentList'
    ]),

    ...mapActions(BILLING_STORE, [
      'setAccumulatedSize',
    ]),

    ...mapActions(USERS_STORE, ['initializeUserState']),

    ...mapActions(USER_WORKSTATION, ['activateWorkstation']),

    setLoaderFalse() {
      setTimeout(() => {
        this.initializing = false;
      }, 300);
    },

    async checkSuccessfulTransaction() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      const { userId } = this.authenticatedUser;

      if (params.session_id) {
        let url = `/payment/${userId}/session?sessionId=${params.session_id}`;

        if (params.is_document) {
          url = `${url}&isDocument=${params.is_document}`;
        }

        await $axios.get(url)
          .then(async (sessionResponse) => {
            if (sessionResponse) {
              this.activateWorkstation({
                userId,
                userWorkstationId: this.activeUserWorkstation.id
              });
              this.setPaymentSuccessfulModal(true);
            }
          });
      }
    },

    async getList(options = {}) {
      this.initializing = true;
      const { userId } = this.authenticatedUser;
      const params = { userId };
      const { parentId } = options;
      const hasParentParam = parentId || null;
      const documentPromises = [];

      if (Object.keys(options).length) {
        params.options = options;
      }

      await this.getUserDocuments(params)
        .then(async (userDocuments) => {
          this.currentFolderList = userDocuments.filter((userDocument) => userDocument.isDir === true
            && userDocument.parentId === hasParentParam);

          this.currentFileList = userDocuments.filter((userDocument) => !userDocument.isDir
            && userDocument.parentId === hasParentParam);
          this.currentFileList = this.currentFileList.map((file) => {
            return { ...file, name: file.originalName, attachment: file.name };
          });

          this.currentFolderList.map((folderItem) => {
            documentPromises.push(this.getUserDocumentCalculatedSize({
              userId,
              userDocumentId: folderItem.id
            }));

            return {
              folderItem
            };
          });

          if (documentPromises.length) {
            const documentFolderSizes = await Promise.all(documentPromises);
            this.currentFolderList = this.currentFolderList.map((item) => {
              const findFolder = documentFolderSizes.find(((x) => x.id === item.id));

              return { ...item, ...findFolder };
            });
          }

          this.setFolderList(this.currentFolderList);
          this.setFileList(this.currentFileList);

          const combinedList = [
            ...this.folderList,
            ...this.fileList,
          ];

          this.setDocumentList(combinedList);
        });

      this.setLoaderFalse();
    },

    async onViewFolder(folder = {}, isBreadcrumbClick = false) {
      const { id } = folder;
      let params = {};

      if ('id' in folder) {
        params = { parentId: id };
      }

      this.setOpenedFolder(folder);

      await this.getList(params)
        .then(() => {
          if (id) {
            if (isBreadcrumbClick) {
              const foundIndex = this.titlePath.findIndex((item) => item.id === id);

              if (foundIndex !== -1) {
                this.titlePath.splice(foundIndex + 1);
              }
            } else {
              this.titlePath.push(folder);
            }
          } else {
            this.resetTitlePath();
          }
        });
    },

    resetTitlePath() {
      this.titlePath = [this.defaultTitlePath];
    },

    setView(event) {
      this.viewType = event;
    },

    async downloadDocument(document) {
      const { originalName, isDir, attachment: keyDoc } = document;

      if (!isDir) {
        const attachment = await this.getAttachment({
          name: USER_DOCUMENTS_CACHE,
          attachment: keyDoc,
          originalName,
          includeUrl: true
        });

        if (attachment && attachment.url) {
          window.open(attachment.url);
        } else {
          // eslint-disable-next-line no-alert
          alert('File not found!');
        }
      } else {
        // eslint-disable-next-line no-alert
        alert('Downloading of folder is disallowed for the moment!');
      }
    }
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.documents {
    max-width: 1230px;
    margin-left: 10px;
    margin-right: 25px;
    height: calc(100% - 3.2rem);

    &__content {
      height: calc(100% - 55px);

      :deep(.el-scrollbar__thumb) {
        display: none;
      }
    }
    &__scrollbar-content {
      // padding: 0 .7rem 0 0;
      gap: 2rem;
      height: 100%;
    }

    &__coming-soon {
      width: 100%;
      height: 100%;
    }
    &__img-wrapper {
      width: 300px;
      height: 250px;

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
    &__construction-text {
      font-size: 25px;
      line-height: 30px;
      font-weight: 700;
      text-align: center;
      color: rgba(12, 15, 74, 0.5);
    }
}
:deep(.el-scrollbar__bar) {
    display: block !important;
  //  :deep(.el-scrollbar__view) {
  //    padding-bottom: 2rem;
  //  }
}
:deep(.el-scrollbar__bar.is-vertical) {
    top: 0;
    right: 0;
    width: 6px !important;
}

@include media-md-max-width() {
  .documents {
    margin: 0 20px;
    height: calc(100% - 2.2rem);
    &__content {
      height: calc(100% - 102px);
    }
    &__scrollbar-content {
      padding: 0 .6rem 2rem 0;
    }
  }
}
@include media-sm-max-width() {
  .documents {
    height: calc(100% - .4rem);
    &__content {
      height: calc(100% - 100px);
    }
  }
}
@include media-sm-max-width() {
  .documents {
    height: calc(100% - .1rem);
  }
}
@include media-xs-max-width() {
  .documents {
    &__content {
      height: calc(100% - 59px);
    }
  }
}
</style>
